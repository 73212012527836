html,body {
  height: 100%;
}

.h-min {
  min-height:800px;
}

.bg-lt {
  background-color: #f7f7f9;
}

.selectable {
  cursor: pointer;
}

.card {
  max-width: 20rem;
}

/* TABLES */

.table th, .table td {
    padding: 0px;
}

.table th, .table td {
    padding: 2px;
    vertical-align: top;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}


/* BORDERS */

.border-bottom-brn {
  border-bottom: 1px solid #6a6a6a;
}

.border-brn {
  border: 1px solid #6a6a6a;
}

.border-top-brn {
  border-top: 1px solid #6a6a6a;
}

.border-left-brn {
  border-left: 1px solid #6a6a6a;
}


.border-right-brn {
  border-right: 1px solid #6a6a6a;
}

.zeropad {
  padding: 0px !important;
}

.zeromargin {
  margin: 0px;
}

.text-brn {
  color:#6a6a6a;
} 

.dotted {

  background-image: radial-gradient(hsla(209, 100%, 47%, 1.00) 5%, transparent 0);
  background-size: 30px 30px;
  background-color: rgba(0,0,0,0.9); /*rgba(0,0,0,0.03)*/

}



.transparent{
  background:rgba(255,255,255,0.5);
}

/* STORM REACT DIAGRAMS */

.srd-default-node__title {
  padding-right: 20px;
  padding-left: 20px;
}

.srd-demo-canvas {
	display: flex;
	min-height: 800px;
	min-width: 600px;
	border: 1px red solid;
}

.ckLXmP {
	background-color: rgba(0,0,0,0.0) !important;
}

.huZNPF {
	width: 100% !important;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.srd-diagram {
	min-height: 600px;
	min-height: 850px;
	min-width: 600px;

}

.separator {
  width: 100%;
  height: 10px;
  border-bottom: 1px dotted grey;
}

/* width */
.customScroll::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #343a40; 
  border-radius: 10px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #6a6a6a; 
  border-radius: 10px;
}